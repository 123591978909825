import React, { createContext, useContext, useState, useEffect } from 'react';

const DarkModeContext = createContext();

export const DarkModeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(() => {
    // Try to get the saved preference from localStorage
    const saved = localStorage.getItem('darkMode');
    return saved !== null ? JSON.parse(saved) : true;
  });

  useEffect(() => {
    // Save to localStorage whenever the mode changes
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
    
    // Update CSS variables for global theming
    document.documentElement.style.setProperty('--bg-primary', darkMode ? '#1a1b1e' : '#ffffff');
    document.documentElement.style.setProperty('--bg-secondary', darkMode ? '#2d2e32' : '#f8f9fa');
    document.documentElement.style.setProperty('--text-primary', darkMode ? '#ffffff' : '#2c3e50');
    document.documentElement.style.setProperty('--text-secondary', darkMode ? '#a0a0a0' : '#6c757d');
    document.documentElement.style.setProperty('--border-color', darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)');
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <DarkModeContext.Provider value={{ darkMode, toggleDarkMode }}>
      {children}
    </DarkModeContext.Provider>
  );
};

export const useDarkMode = () => {
  const context = useContext(DarkModeContext);
  if (context === undefined) {
    throw new Error('useDarkMode must be used within a DarkModeProvider');
  }
  return context;
};
