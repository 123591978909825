import React from 'react';
import { FaGraduationCap, FaLightbulb, FaBriefcase } from 'react-icons/fa';
import { ReactComponent as Microscope } from './assets/microscope.svg';
import './ueberuns.scss';

const HeroSection = () => (
  <section className="hero-section">
    <div className="hero-content">
      <div className="text-content">
        <h1>Wir sind MTL</h1>
        <p>Eine zentrale Anlaufstelle für medizinische Technologen für Laboratoriumsanalytik.</p>
      </div>
      <div className="hero-visual">
        <div className="microscope-wrapper">
          <div className="orbit" />
          <Microscope />
        </div>
      </div>
    </div>
  </section>
);

const IntroSection = () => (
  <section className="content-section">
    <p>
      Diese Website ist im Rahmen eines Gruppenprojekts der Klasse MTL02 der FiT Ausbildungsakademie entstanden. 
      Unser Ziel war es, eine zentrale Anlaufstelle für MTL zu schaffen, die sich über ihre Karrierechancen und 
      Weiterbildungsmöglichkeiten informieren möchten.
    </p>
  </section>
);

const FeatureCard = ({ icon, title, description }) => (
  <div className="feature-card">
    <div className="icon-wrapper">
      {icon}
    </div>
    <h3>{title}</h3>
    <p>{description}</p>
  </div>
);

const FeaturesSection = () => (
  <section className="features-section">
    <div className="features-grid">
      <FeatureCard 
        icon={<FaLightbulb className="feature-icon" />}
        title="KI-Karriereberatung"
        description="Individuelle Vorschläge für deinen beruflichen Werdegang."
      />
      <FeatureCard 
        icon={<FaGraduationCap className="feature-icon" />}
        title="Karriere-Tipps"
        description="Hilfreiche Tipps für deine berufliche Zukunft."
      />
      <FeatureCard 
        icon={<FaBriefcase className="feature-icon" />}
        title="Stellenanzeigen"
        description="Aktuelle Jobangebote in der MTL-Branche."
      />
    </div>
  </section>
);

const MissionSection = () => (
  <section className="mission-section">
    <div className="mission-content">
      <p>
        Wir unterstützen dich bei deiner beruflichen Entwicklung – 
        sei es durch eine Spezialisierung, ein Studium oder eine leitende Position.
      </p>
      <p className="closing">
        Viel Erfolg auf deinem Weg!
      </p>
    </div>
  </section>
);

const UeberUns = () => (
  <div className="ueber-uns-container">
    <HeroSection />
    <IntroSection />
    <FeaturesSection />
    <MissionSection />
  </div>
);

export default UeberUns;
