import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink, Link } from 'react-router-dom';
import Karrieretipps from './karrieretipps';
import JobSearch from './JobSearch';
import UeberUns from './ueberuns';
import Datenschutzerklärung from './Datenschutzerklärung';
import Impressum from './Impressum';
import Quellen from './Quellen';
import Kontakt from './Kontakt';
import ReactMarkdown from 'react-markdown';
import { FaSun, FaMoon, FaSearch, FaChevronRight, FaHome, FaLightbulb, FaBriefcase, FaUsers, FaEnvelope, FaRobot, FaBookmark, FaGraduationCap, FaLinkedin, FaXing, FaTwitter, FaInstagram, FaThumbsUp, FaThumbsDown } from 'react-icons/fa';
import { DarkModeProvider, useDarkMode } from './contexts/DarkModeContext';
import 'ldrs/helix';
import 'ldrs/ring2';
import './App.scss';

const AppContent = () => {
  const { darkMode, toggleDarkMode } = useDarkMode();
  const [selectedJob, setSelectedJob] = useState('');
  const [loading, setLoading] = useState(false);
  const [careerInfo, setCareerInfo] = useState('');
  const [isScrolled, setIsScrolled] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [savedTips, setSavedTips] = useState([]);

  useEffect(() => {
    // Add smooth scroll behavior
    document.documentElement.style.scrollBehavior = 'smooth';
    
    // Update data-theme attribute for CSS variables
    document.documentElement.setAttribute('data-theme', darkMode ? 'dark' : 'light');
    
    // Handle navigation blur effect on scroll
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    
    window.addEventListener('scroll', handleScroll);
    
    // Add fade-in animation to containers
    const containers = document.querySelectorAll('.container');
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in');
          }
        });
      },
      { threshold: 0.1 }
    );
    
    containers.forEach(container => observer.observe(container));
    
    return () => {
      containers.forEach(container => observer.unobserve(container));
      window.removeEventListener('scroll', handleScroll);
    };
  }, [darkMode]);

  const handleJobChange = (event) => {
    setSelectedJob(event.target.value);
  };

  const handleSubmit = async () => {
    if (selectedJob.trim() === '') return;
    setLoading(true);
    setCareerInfo('');
    try {
      const response = await fetchGPTResponse(selectedJob);
      setCareerInfo(response);
    } catch (error) {
      console.error('Error fetching career info:', error);
      setCareerInfo('Es tut uns leid, aber es gab einen Fehler bei der Verarbeitung Ihrer Anfrage. Bitte versuchen Sie es später erneut.');
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const fetchGPTResponse = async (selectedJob) => {
    const apiKey = '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';
    const url = 'https://api.openai.com/v1/chat/completions';

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiKey}`,
      },
      body: JSON.stringify({
        model: 'gpt-3.5-turbo',
        messages: [
          { role: 'system', content: 'Du bist ein Profi in der Karrierenberatung spezialisiert auf Bio und Medizin' },
          { role: 'user', content: `Ich bin zur Zeit ein ${selectedJob}, welche Karrieremöglichkeiten habe?` },
        ],
      }),
    });

    const data = await response.json();
    return data.choices[0].message.content;
  };

  return (
    <div className="app" data-theme={darkMode ? 'dark' : 'light'}>
      <nav className={`navigation-bar ${isScrolled ? 'scrolled' : ''}`}>
        <NavLink to="/" className="brand">
          MTL Karriere
        </NavLink>
        <div className="nav-links">
          <NavLink to="/" className="nav-link" end>
            <FaHome className="nav-icon" />
            <span>Home</span>
          </NavLink>
          <NavLink to="/karrieretipps" className="nav-link">
            <FaLightbulb className="nav-icon" />
            <span>Karrieretipps</span>
          </NavLink>
          <NavLink to="/jobs" className="nav-link">
            <FaBriefcase className="nav-icon" />
            <span>Jobsuche</span>
          </NavLink>
          <NavLink to="/ueber-uns" className="nav-link">
            <FaUsers className="nav-icon" />
            <span>Über uns</span>
          </NavLink>
          <NavLink to="/kontakt" className="nav-link">
            <FaEnvelope className="nav-icon" />
            <span>Kontakt</span>
          </NavLink>
        </div>
        <div className="nav-right">
          <button 
            className="theme-toggle"
            onClick={toggleDarkMode}
            aria-label={darkMode ? 'Light Mode aktivieren' : 'Dark Mode aktivieren'}
          >
            {darkMode ? <FaSun className="theme-icon" /> : <FaMoon className="theme-icon" />}
          </button>
        </div>
      </nav>

      <main className="main-content">
        <Routes>
          <Route
            path="/"
            element={
              <div className="home-container">
                <section className="hero-section">
                  <h1>Willkommen bei MTL Karriere</h1>
                  <p className="subtitle">Mit KI-gestützter Beratung zu deinem Traumjob</p>
                  <div className="search-container">
                    <div className="input-group">
                      <FaBriefcase className="input-icon" />
                      <input
                        type="text"
                        value={selectedJob}
                        onChange={handleJobChange}
                        onKeyPress={handleKeyPress}
                        placeholder="Welcher Beruf interessiert dich?"
                        className="modern-input"
                      />
                      <button 
                        onClick={handleSubmit}
                        className="submit-button"
                        disabled={loading || !selectedJob.trim()}
                      >
                        {loading ? (
                          <l-ring2
                            size="20"
                            stroke="2"
                            speed="0.9"
                            color="white"
                          ></l-ring2>
                        ) : (
                          <>
                            <span>Entdecken</span>
                            <FaChevronRight className="icon" />
                          </>
                        )}
                      </button>
                    </div>
                  </div>

                  {loading && (
                    <div className="loading-container">
                      <l-helix
                        size="45"
                        speed="2.5"
                        color="var(--primary-color)"
                      ></l-helix>
                      <p className="loading-text">Analysiere Karrieremöglichkeiten...</p>
                    </div>
                  )}

                  {!loading && careerInfo && (
                    <div className="career-info">
                      <ReactMarkdown>{careerInfo}</ReactMarkdown>
                    </div>
                  )}
                </section>

                <section className="features-section">
                  <h2>Unsere Features</h2>
                  <div className="features-grid">
                    <div className="feature-card">
                      <FaLightbulb className="feature-icon" />
                      <h3>Karrieretipps</h3>
                      <p>Professionelle Ratschläge und Strategien für deinen Karriereweg</p>
                    </div>
                    <div className="feature-card">
                      <FaBriefcase className="feature-icon" />
                      <h3>Jobsuche</h3>
                      <p>Finde passende Stellenangebote in deiner Branche</p>
                    </div>
                    <div className="feature-card">
                      <FaGraduationCap className="feature-icon" />
                      <h3>Weiterbildung</h3>
                      <p>Entdecke Möglichkeiten zur beruflichen Entwicklung</p>
                    </div>
                  </div>
                </section>

                <section className="cta-section">
                  <h2>Bereit für den nächsten Schritt?</h2>
                  <p>Starte noch heute mit MTL Karriere und gestalte deine berufliche Zukunft.</p>
                  <div className="cta-buttons">
                    <Link to="/karrieretipps" className="cta-button primary">
                      Karrieretipps entdecken
                    </Link>
                    <Link to="/jobs" className="cta-button secondary">
                      Jobs durchsuchen
                    </Link>
                  </div>
                </section>
              </div>
            }
          />
          <Route path="/karrieretipps" element={<Karrieretipps />} />
          <Route path="/jobs" element={<JobSearch />} />
          <Route path="/ueber-uns" element={<UeberUns />} />
          <Route path="/kontakt" element={<Kontakt />} />
          <Route path="/quellen" element={<Quellen />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/datenschutzerklärung" element={<Datenschutzerklärung />} />
        </Routes>

        <footer className="footer">
          <div className="footer-content">
            <div className="footer-bottom">
              <div className="footer-links">
                <Link to="/Impressum">Impressum</Link>
                <span className="separator">•</span>
                <Link to="/Datenschutzerklärung">Datenschutzerklärung</Link>
                <span className="separator">•</span>
                <Link to="/Quellen">Quellen</Link>
              </div>
              <p className="copyright">
                {new Date().getFullYear()} MTL Karriere. Alle Rechte vorbehalten.
              </p>
            </div>
          </div>
        </footer>
      </main>
    </div>
  );
};

const App = () => {
  return (
    <DarkModeProvider>
      <Router>
        <AppContent />
      </Router>
    </DarkModeProvider>
  );
};

export default App;
