import React, { useState } from 'react';
import { FaSearch, FaBookmark, FaBriefcase, FaGraduationCap, FaHandshake, FaLaptopCode, FaChevronRight, FaClock } from 'react-icons/fa';
import ArticleModal from './components/ArticleModal';
import './karrieretipps.scss';

const articlesContent = {
  1: {
    content: [
      {
        title: 'Die wichtigsten Elemente eines professionellen Lebenslaufs',
        text: 'Ein überzeugender Lebenslauf ist der Schlüssel zu deinem Traumjob. Er ist deine persönliche Marketing-Broschüre und oft der erste Eindruck, den ein potenzieller Arbeitgeber von dir erhält.'
      },
      {
        title: 'Struktur und Layout',
        list: [
          'Klare, übersichtliche Gliederung',
          'Moderne, professionelle Schriftart',
          'Konsistentes Design',
          'Maximal 2 Seiten Länge',
          'PDF-Format für die Bewerbung'
        ]
      },
      {
        title: 'Inhaltliche Gestaltung',
        list: [
          'Persönliche Daten kurz und relevant',
          'Chronologisch rückwärts sortierter Werdegang',
          'Konkrete Erfolge und Leistungen',
          'Relevante Fähigkeiten und Zertifikate',
          'Aktuelle Kontaktdaten'
        ]
      },
      {
        text: 'Besonders wichtig ist es, den Lebenslauf auf die jeweilige Stelle anzupassen. Hebe die Erfahrungen und Fähigkeiten hervor, die für die ausgeschriebene Position besonders relevant sind.'
      }
    ],
    resources: [
      {
        title: 'Lebenslauf-Vorlagen',
        url: '#',
        description: 'Professionelle Templates für verschiedene Branchen'
      },
      {
        title: 'Checkliste: Lebenslauf-Optimierung',
        url: '#',
        description: 'Schritt-für-Schritt-Anleitung zur Verbesserung deines CVs'
      }
    ]
  },
  2: {
    content: [
      {
        title: 'Perfekte Vorbereitung ist der Schlüssel',
        text: 'Ein erfolgreiches Vorstellungsgespräch basiert auf gründlicher Vorbereitung. Je besser du vorbereitet bist, desto selbstsicherer wirst du auftreten.'
      },
      {
        title: 'Vor dem Gespräch',
        list: [
          'Recherchiere das Unternehmen gründlich',
          'Bereite Antworten auf häufige Fragen vor',
          'Übe mit einem Freund oder Mentor',
          'Plane deine Anreise',
          'Wähle passende Kleidung'
        ]
      },
      {
        title: 'Während des Gesprächs',
        list: [
          'Sei pünktlich (10-15 Minuten früher)',
          'Achte auf deine Körpersprache',
          'Höre aktiv zu',
          'Stelle eigene Fragen',
          'Bleibe authentisch'
        ]
      },
      {
        title: 'Nach dem Gespräch',
        list: [
          'Sende eine Dankes-E-Mail',
          'Reflektiere das Gespräch',
          'Notiere offene Fragen',
          'Plane nächste Schritte'
        ]
      }
    ],
    resources: [
      {
        title: 'Die 50 häufigsten Bewerbungsfragen',
        url: '#',
        description: 'Mit Beispielantworten und Erklärungen'
      },
      {
        title: 'Körpersprache im Vorstellungsgespräch',
        url: '#',
        description: 'Video-Guide für positives Auftreten'
      }
    ]
  }
};

const tips = [
  {
    id: 1,
    title: 'Der perfekte Lebenslauf',
    description: 'Erstelle einen überzeugenden Lebenslauf, der dich von anderen Bewerbern abhebt. Mit unseren professionellen Tipps und Vorlagen.',
    category: 'bewerbung',
    image: 'https://images.unsplash.com/photo-1586281380349-632531db7ed4?q=80&w=800&h=450&fit=crop',
    readingTime: '7 min',
    isBookmarked: false,
    author: 'Timo Haseloff',
    date: '15. Feb 2024'
  },
  {
    id: 2,
    title: 'Vorstellungsgesprách meistern',
    description: 'Von der Vorbereitung bis zum Follow-up: Dein kompletter Guide für ein erfolgreiches Vorstellungsgesprách.',
    category: 'vorstellung',
    image: 'https://images.unsplash.com/photo-1565843708714-52ecf69ab81f?q=80&w=800&h=450&fit=crop',
    readingTime: '10 min',
    isBookmarked: true,
    author: 'Timo Haseloff',
    date: '12. Feb 2024'
  },
  {
    id: 3,
    title: 'Karriereziele strategisch planen',
    description: 'Setze dir smarte Ziele und entwickle einen konkreten Aktionsplan für deine berufliche Entwicklung.',
    category: 'entwicklung',
    image: 'https://images.unsplash.com/photo-1434626881859-194d67b2b86f?q=80&w=800&h=450&fit=crop',
    readingTime: '8 min',
    isBookmarked: false,
    author: 'Timo Haseloff',
    date: '10. Feb 2024'
  },
  {
    id: 4,
    title: 'Kommunikation im Team',
    description: 'Verbessere deine Soft Skills und lerne, wie du effektiv im Team kommunizierst und Konflikte löst.',
    category: 'skills',
    image: 'https://images.unsplash.com/photo-1522071820081-009f0129c71c?q=80&w=800&h=450&fit=crop',
    readingTime: '6 min',
    isBookmarked: false,
    author: 'Timo Haseloff',
    date: '8. Feb 2024'
  },
  {
    id: 5,
    title: 'Digital Skills 2024',
    description: 'Die wichtigsten digitalen Kompetenzen für deinen beruflichen Erfolg im Jahr 2024.',
    category: 'skills',
    image: 'https://images.unsplash.com/photo-1571171637578-41bc2dd41cd2?q=80&w=800&h=450&fit=crop',
    readingTime: '12 min',
    isBookmarked: true,
    author: 'Timo Haseloff',
    date: '5. Feb 2024'
  },
  {
    id: 6,
    title: 'Remote Work Excellence',
    description: 'Best Practices für effektives Arbeiten im Home Office und virtuelle Zusammenarbeit.',
    category: 'skills',
    image: 'https://images.unsplash.com/photo-1593642532744-d377ab507dc8?q=80&w=800&h=450&fit=crop',
    readingTime: '9 min',
    isBookmarked: false,
    author: 'Timo Haseloff',
    date: '3. Feb 2024'
  }
];

const categories = [
  { id: 'all', label: 'Alle Tipps', icon: null },
  { id: 'bewerbung', label: 'Bewerbung', icon: FaBriefcase },
  { id: 'vorstellung', label: 'Vorstellungsgespräch', icon: FaHandshake },
  { id: 'entwicklung', label: 'Karriereentwicklung', icon: FaGraduationCap },
  { id: 'skills', label: 'Skills & Kompetenzen', icon: FaLaptopCode }
];

const Karrieretipps = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [showBookmarksOnly, setShowBookmarksOnly] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [bookmarkedTips, setBookmarkedTips] = useState(() => {
    const saved = localStorage.getItem('bookmarkedTips');
    return saved ? JSON.parse(saved) : [];
  });

  const toggleBookmark = (tipId) => {
    setBookmarkedTips(prev => {
      const newBookmarks = prev.includes(tipId)
        ? prev.filter(id => id !== tipId)
        : [...prev, tipId];
      
      localStorage.setItem('bookmarkedTips', JSON.stringify(newBookmarks));
      return newBookmarks;
    });
  };

  const openArticle = (tip) => {
    setSelectedArticle({
      ...tip,
      isBookmarked: bookmarkedTips.includes(tip.id),
      ...articlesContent[tip.id]
    });
  };

  const filteredTips = tips.filter(tip => {
    const matchesSearch = tip.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                         tip.description.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesCategory = selectedCategory === 'all' || tip.category === selectedCategory;
    const matchesBookmarks = !showBookmarksOnly || bookmarkedTips.includes(tip.id);
    return matchesSearch && matchesCategory && matchesBookmarks;
  }).map(tip => ({
    ...tip,
    isBookmarked: bookmarkedTips.includes(tip.id)
  }));

  return (
    <div className="karrieretipps-container">
      <div className="hero-section">
        <h1>Karrieretipps</h1>
        <p className="subtitle">Professionelle Tipps für deinen Karriereerfolg</p>
        <div className="search-bar">
          <FaSearch className="search-icon" />
          <input
            type="text"
            placeholder="Suche nach Karrieretipps..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>

      <div className="filter-section">
        <div className="categories-section">
          {categories.map(category => (
            <button
              key={category.id}
              className={`category-button ${selectedCategory === category.id ? 'active' : ''}`}
              onClick={() => setSelectedCategory(category.id)}
            >
              {category.icon && <category.icon className="category-icon" />}
              {category.label}
            </button>
          ))}
        </div>
        <button
          className={`bookmark-filter ${showBookmarksOnly ? 'active' : ''}`}
          onClick={() => setShowBookmarksOnly(!showBookmarksOnly)}
        >
          <FaBookmark /> {showBookmarksOnly ? 'Alle anzeigen' : 'Gespeicherte'}
        </button>
      </div>

      <div className="tips-grid">
        {filteredTips.map((tip, index) => (
          <article 
            className="tip-card" 
            key={tip.id}
            style={{'--animation-order': index}}
          >
            <div className="tip-image-container">
              <img src={tip.image} alt={tip.title} className="tip-image" />
              <button 
                className={`bookmark-button ${tip.isBookmarked ? 'active' : ''}`}
                aria-label={tip.isBookmarked ? 'Entfernen' : 'Merken'}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleBookmark(tip.id);
                }}
              >
                <FaBookmark />
              </button>
            </div>
            <div className="tip-content">
              <span className="tip-category">
                {(() => {
                  const category = categories.find(c => c.id === tip.category);
                  const Icon = category?.icon;
                  return (
                    <>
                      {Icon && <Icon />}
                      {category?.label}
                    </>
                  );
                })()}
              </span>
              <h3>{tip.title}</h3>
              <p>{tip.description}</p>
              <div className="tip-meta">
                <div className="meta-left">
                  <span className="reading-time">
                    <FaClock /> {tip.readingTime}
                  </span>
                  <span className="author">{tip.author}</span>
                </div>
                <button 
                  className="read-more"
                  onClick={() => openArticle(tip)}
                >
                  Mehr lesen <FaChevronRight />
                </button>
              </div>
            </div>
          </article>
        ))}
      </div>

      {filteredTips.length === 0 && (
        <div className="empty-state">
          <FaSearch className="empty-icon" />
          <h3>Keine Ergebnisse gefunden</h3>
          <p>Versuche es mit anderen Suchbegriffen oder wähle eine andere Kategorie.</p>
          <button 
            className="reset-button"
            onClick={() => {
              setSearchQuery('');
              setSelectedCategory('all');
              setShowBookmarksOnly(false);
            }}
          >
            Zurücksetzen
          </button>
        </div>
      )}

      <ArticleModal 
        article={selectedArticle}
        onClose={() => setSelectedArticle(null)}
        onBookmark={(id) => toggleBookmark(id)}
      />
    </div>
  );
};

export default Karrieretipps;
