import React from 'react';
import { FaTimes, FaBookmark, FaClock, FaShare } from 'react-icons/fa';
import './ArticleModal.scss';

const ArticleModal = ({ article, onClose, onBookmark }) => {
  if (!article) return null;

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: article.title,
        text: article.description,
        url: window.location.href
      });
    }
  };

  return (
    <div className="article-modal-overlay" onClick={onClose}>
      <article className="article-modal" onClick={e => e.stopPropagation()}>
        <div className="modal-header">
          <button className="close-button" onClick={onClose}>
            <FaTimes />
          </button>
          <div className="modal-actions">
            <button 
              className={`bookmark-button ${article.isBookmarked ? 'active' : ''}`}
              onClick={() => onBookmark(article.id)}
            >
              <FaBookmark />
            </button>
            <button className="share-button" onClick={handleShare}>
              <FaShare />
            </button>
          </div>
        </div>

        <div className="article-image">
          <img src={article.image} alt={article.title} />
        </div>

        <div className="article-content">
          <div className="article-meta">
            <span className="reading-time">
              <FaClock /> {article.readingTime}
            </span>
            <span className="author">{article.author}</span>
            <span className="date">{article.date}</span>
          </div>

          <h1>{article.title}</h1>
          <p className="description">{article.description}</p>

          {article.content && article.content.map((section, index) => (
            <section key={index} className="content-section">
              {section.title && <h2>{section.title}</h2>}
              {section.text && <p>{section.text}</p>}
              {section.list && (
                <ul>
                  {section.list.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              )}
            </section>
          ))}

          {article.resources && (
            <section className="resources">
              <h2>Weiterführende Ressourcen</h2>
              <ul>
                {article.resources.map((resource, index) => (
                  <li key={index}>
                    <a href={resource.url} target="_blank" rel="noopener noreferrer">
                      {resource.title}
                    </a>
                    {resource.description && (
                      <p>{resource.description}</p>
                    )}
                  </li>
                ))}
              </ul>
            </section>
          )}
        </div>
      </article>
    </div>
  );
};

export default ArticleModal;
