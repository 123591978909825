import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { 
  FaEnvelope, FaClock,
  FaLinkedin, FaXing, FaTwitter, FaInstagram,
  FaPaperPlane, FaCheck, FaExclamationTriangle
} from 'react-icons/fa';
import './Kontakt.scss';

const Kontakt = () => {
  const form = useRef();
  const [status, setStatus] = useState('idle'); // idle, sending, success, error
  const [errorMessage, setErrorMessage] = useState('');
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(null);

  const sendEmail = (e) => {
    e.preventDefault();
    setStatus('sending');
    setSending(true);
    setError(null);

    emailjs.sendForm(
      'service_mtl_karriere', // Ersetze dies mit deiner Service ID von EmailJS
      'template_m2v5m5j', // Ersetze dies mit deiner Template ID von EmailJS
      form.current,
      'LPbQ3xUMXsgr0lxvN' // Ersetze dies mit deinem Public Key von EmailJS
    )
      .then(() => {
        setStatus('success');
        setSent(true);
        form.current.reset();
      })
      .catch((error) => {
        setStatus('error');
        setErrorMessage(error.text);
        setError('Es gab einen Fehler beim Senden. Bitte versuchen Sie es später erneut.');
      })
      .finally(() => {
        setSending(false);
      });
  };

  return (
    <div className="kontakt-container">
      <div className="hero-section">
        <h1>Kontaktiere uns</h1>
        <p className="subtitle">Wir sind für dich da und helfen dir gerne weiter</p>
      </div>

      <div className="content-grid">
        <div className="contact-info">
          <div className="info-card">
            <div className="icon-wrapper">
              <FaEnvelope />
            </div>
            <h3>E-Mail</h3>
            <p>info@mtl-karriere.de</p>
            <a href="mailto:info@mtl-karriere.de" className="contact-link">
              E-Mail senden
            </a>
          </div>

          <div className="info-card">
            <div className="icon-wrapper">
              <FaClock />
            </div>
            <h3>Öffnungszeiten</h3>
            <p>Mo. - Fr.: 9:00 - 17:00<br />Sa. & So.: Geschlossen</p>
          </div>
        </div>

        <div className="contact-form-section">
          <div className="form-container">
            <h2>Schreib uns eine Nachricht</h2>
            <p>Wir melden uns schnellstmöglich bei dir zurück</p>

            {sent ? (
              <div className="success-message">
                <FaCheck />
                <h3>Nachricht gesendet!</h3>
                <p>Vielen Dank für deine Nachricht. Wir werden uns in Kürze bei dir melden.</p>
                <button 
                  className="new-message-button"
                  onClick={() => setSent(false)}
                >
                  Neue Nachricht
                </button>
              </div>
            ) : (
              <form ref={form} onSubmit={sendEmail}>
                <div className="form-group">
                  <label htmlFor="user_name">Name</label>
                  <input
                    type="text"
                    id="user_name"
                    name="user_name"
                    required
                    placeholder="Dein Name"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="user_email">E-Mail</label>
                  <input
                    type="email"
                    id="user_email"
                    name="user_email"
                    required
                    placeholder="deine@email.de"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="subject">Betreff</label>
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    required
                    placeholder="Worum geht es?"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="message">Nachricht</label>
                  <textarea
                    id="message"
                    name="message"
                    required
                    placeholder="Deine Nachricht an uns..."
                    rows="5"
                  />
                </div>

                {error && (
                  <div className="error-message">
                    {error}
                  </div>
                )}

                <button 
                  type="submit" 
                  className={`submit-button ${status}`}
                  disabled={sending}
                >
                  {status === 'idle' && (
                    <>
                      <FaPaperPlane /> Nachricht senden
                    </>
                  )}
                  {status === 'sending' && 'Wird gesendet...'}
                  {status === 'success' && (
                    <>
                      <FaCheck /> Nachricht gesendet!
                    </>
                  )}
                  {status === 'error' && (
                    <>
                      <FaExclamationTriangle /> Fehler beim Senden
                    </>
                  )}
                </button>

                {status === 'error' && (
                  <div className="error-message">
                    {errorMessage || 'Ein Fehler ist aufgetreten. Bitte versuche es später erneut.'}
                  </div>
                )}
              </form>
            )}
          </div>
        </div>
      </div>

      <div className="social-section">
        <h2>Folge uns</h2>
        <div className="social-links">
          <a href="#" className="social-link linkedin">
            <FaLinkedin />
            <span>LinkedIn</span>
          </a>
          <a href="#" className="social-link xing">
            <FaXing />
            <span>Xing</span>
          </a>
          <a href="#" className="social-link twitter">
            <FaTwitter />
            <span>Twitter</span>
          </a>
          <a href="#" className="social-link instagram">
            <FaInstagram />
            <span>Instagram</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Kontakt;
