import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  FaSearch, FaMapMarkerAlt, FaBriefcase, FaEuroSign, 
  FaBookmark, FaFilter, FaClock, FaBuilding, FaTimes,
  FaChevronDown, FaChevronUp, FaCompass
} from 'react-icons/fa';
import './JobSearch.scss';

const locations = ['Alle Orte', 'Berlin', 'München', 'Hamburg', 'Frankfurt', 'Remote'];
const types = ['Alle Arten', 'Vollzeit', 'Teilzeit', 'Befristet', 'Unbefristet'];
const experiences = ['Alle', 'Berufseinsteiger', 'Berufserfahrene', 'Führungskräfte'];
const radiusOptions = [
  { value: 0, label: 'Exakter Ort' },
  { value: 10, label: '10 km' },
  { value: 25, label: '25 km' },
  { value: 50, label: '50 km' },
  { value: 100, label: '100 km' },
  { value: 200, label: '200 km' }
];

const JobSearch = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [location, setLocation] = useState('');
  const [selectedRadius, setSelectedRadius] = useState(25);
  const [selectedType, setSelectedType] = useState('Alle Arten');
  const [selectedExperience, setSelectedExperience] = useState('Alle');
  const [showFilters, setShowFilters] = useState(false);
  const [savedJobs, setSavedJobs] = useState(() => {
    const saved = localStorage.getItem('savedJobs');
    return saved ? JSON.parse(saved) : [];
  });
  const [selectedJob, setSelectedJob] = useState(null);
  const [jobDetails, setJobDetails] = useState(null);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [sortBy, setSortBy] = useState('newest');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [totalJobs, setTotalJobs] = useState(0);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    localStorage.setItem('savedJobs', JSON.stringify(savedJobs));
  }, [savedJobs]);

  const loadJobDetails = async (job) => {
    setLoadingDetails(true);
    try {
      const response = await axios.get(
        `https://rest.arbeitsagentur.de/jobboerse/jobsuche-service/pc/v4/jobs/${job.hashId}`,
        { headers: { 'X-API-Key': 'jobboerse-jobsuche' } }
      );
      setJobDetails(response.data);
    } catch (err) {
      console.error('Error loading job details:', err);
    } finally {
      setLoadingDetails(false);
    }
  };

  const openJobDetails = (job) => {
    setSelectedJob(job);
    setJobDetails(null);
    loadJobDetails(job);
  };

  const searchJobs = async (resetPage = true) => {
    if (resetPage) {
      setPage(1);
      setJobs([]);
    }

    setLoading(true);
    setError(null);

    try {
      const apiUrl = 'https://rest.arbeitsagentur.de/jobboerse/jobsuche-service/pc/v4/jobs';
      
      const params = {
        was: searchQuery,
        wo: location,
        umkreis: selectedRadius,
        page: resetPage ? 1 : page,
        size: 20,
        arbeitszeitmodelle: selectedType !== 'Alle Arten' ? [
          selectedType === 'Vollzeit' ? 1 : 
          selectedType === 'Teilzeit' ? 2 :
          selectedType === 'Befristet' ? 5 :
          selectedType === 'Unbefristet' ? 6 : null
        ].filter(Boolean) : undefined,
        berufsfeld: selectedExperience !== 'Alle' ? [
          selectedExperience === 'Berufseinsteiger' ? 1 :
          selectedExperience === 'Berufserfahrene' ? 2 :
          selectedExperience === 'Führungskräfte' ? 3 : null
        ].filter(Boolean) : undefined
      };

      const headers = {
        'X-API-Key': 'jobboerse-jobsuche'
      };

      const response = await axios.get(apiUrl, { params, headers });
      
      // Hole Details für jeden Job
      const jobsWithDetails = await Promise.all(
        (response.data.stellenangebote || []).map(async (job) => {
          try {
            const detailResponse = await axios.get(
              `https://rest.arbeitsagentur.de/jobboerse/jobsuche-service/pc/v4/jobs/${job.hashId}`,
              { headers }
            );
            return { ...job, details: detailResponse.data };
          } catch (err) {
            console.error('Error fetching job details:', err);
            return job;
          }
        })
      );

      setJobs(prev => resetPage ? jobsWithDetails : [...prev, ...jobsWithDetails]);
      setTotalJobs(response.data.maxErgebnisse || 0);
      setHasMore((resetPage ? 1 : page) * 20 < response.data.maxErgebnisse);
      
    } catch (err) {
      setError('Fehler beim Laden der Stellenangebote. Bitte versuche es später erneut.');
      console.error('API Error:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchQuery || location) {
      const timeoutId = setTimeout(() => {
        searchJobs(true);
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [searchQuery, location, selectedRadius, selectedType, selectedExperience]);

  const loadMore = () => {
    setPage(prev => prev + 1);
    searchJobs(false);
  };

  const toggleSaveJob = (jobId) => {
    setSavedJobs(prev => {
      const newSaved = prev.includes(jobId)
        ? prev.filter(id => id !== jobId)
        : [...prev, jobId];
      return newSaved;
    });
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const now = new Date();
    const diffTime = Math.abs(now - date);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    if (diffDays === 1) return 'Vor 1 Tag';
    if (diffDays < 30) return `Vor ${diffDays} Tagen`;
    return new Intl.DateTimeFormat('de-DE', { 
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).format(date);
  };

  return (
    <div className="job-search-container">
      <div className="hero-section">
        <h1>Finde deinen Traumjob</h1>
        <p className="subtitle">Durchsuche tausende Jobs von Top-Unternehmen</p>
        
        <div className="search-bar">
          <div className="search-input job-input">
            <FaBriefcase className="search-icon" />
            <input
              type="text"
              placeholder="Jobtitel oder Stichwörter"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className="search-input location-input">
            <FaMapMarkerAlt className="search-icon" />
            <input
              type="text"
              placeholder="Ort oder PLZ"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
          </div>
          <button 
            className="filter-button"
            onClick={() => setShowFilters(!showFilters)}
          >
            <FaFilter /> Filter
          </button>
        </div>

        <div className={`filters-panel ${showFilters ? 'show' : ''}`}>
          <div className="filters-grid">
            <div className="filter-group">
              <label>Umkreis</label>
              <select
                value={selectedRadius}
                onChange={(e) => setSelectedRadius(Number(e.target.value))}
              >
                {radiusOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="filter-group">
              <label>Art der Beschäftigung</label>
              <select
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
              >
                {types.map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
            </div>

            <div className="filter-group">
              <label>Erfahrungslevel</label>
              <select
                value={selectedExperience}
                onChange={(e) => setSelectedExperience(e.target.value)}
              >
                {experiences.map(exp => (
                  <option key={exp} value={exp}>{exp}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="results-section">
        <div className="results-header">
          <div className="results-count">
            {totalJobs} Stellen gefunden
          </div>
          <div className="sort-options">
            <select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
            >
              <option value="newest">Neueste zuerst</option>
              <option value="relevance">Relevanz</option>
            </select>
          </div>
        </div>

        {error && (
          <div className="error-message">
            {error}
          </div>
        )}

        <div className="jobs-grid">
          {jobs.map(job => (
            <div 
              key={job.hashId} 
              className={`job-card ${selectedJob?.hashId === job.hashId ? 'selected' : ''}`}
              onClick={() => openJobDetails(job)}
            >
              <div className="job-header">
                <h3>{job.titel}</h3>
                <button
                  className={`bookmark-button ${savedJobs.includes(job.hashId) ? 'active' : ''}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSaveJob(job.hashId);
                  }}
                >
                  <FaBookmark />
                </button>
              </div>

              <div className="company-info">
                <FaBuilding /> {job.arbeitgeber}
              </div>

              <div className="job-meta">
                <span><FaMapMarkerAlt /> {job.arbeitsort?.ort}</span>
                <span><FaBriefcase /> {job.arbeitszeitmodell?.bezeichnung || 'Nicht angegeben'}</span>
                {job.details?.entgeltInformation && (
                  <span><FaEuroSign /> {job.details.entgeltInformation}</span>
                )}
              </div>

              <p className="job-description">
                {job.details?.stellenbeschreibung || job.kurzbezeichnung || 'Keine Beschreibung verfügbar'}
              </p>

              <div className="job-footer">
                <span className="posted-time">
                  <FaClock /> {formatDate(job.erstellungsdatum)}
                </span>
                <button className="details-button">
                  Details {selectedJob?.hashId === job.hashId ? <FaChevronUp /> : <FaChevronDown />}
                </button>
              </div>
            </div>
          ))}
        </div>

        {loading && (
          <div className="loading-container">
            <div className="loading-spinner" />
            <p>Lade Stellenangebote...</p>
          </div>
        )}

        {!loading && hasMore && jobs.length > 0 && (
          <button className="load-more-button" onClick={loadMore}>
            Weitere Jobs laden
          </button>
        )}

        {selectedJob && (
          <div className="job-details-panel">
            <div className="panel-header">
              <button className="close-button" onClick={() => setSelectedJob(null)}>
                <FaTimes />
              </button>
              <h2>{selectedJob.titel}</h2>
              <div className="company-info">
                <FaBuilding /> {selectedJob.arbeitgeber}
              </div>
              <div className="job-meta">
                <span><FaMapMarkerAlt /> {selectedJob.arbeitsort?.ort}</span>
                <span><FaBriefcase /> {selectedJob.arbeitszeitmodell?.bezeichnung || 'Nicht angegeben'}</span>
              </div>
            </div>

            <div className="panel-content">
              {loadingDetails ? (
                <div className="loading-container">
                  <div className="loading-spinner" />
                  <p>Lade Details...</p>
                </div>
              ) : jobDetails ? (
                <>
                  <section>
                    <h3>Stellenbeschreibung</h3>
                    <p>{jobDetails.stellenbeschreibung || selectedJob.kurzbezeichnung}</p>
                  </section>

                  {jobDetails.aufgaben && jobDetails.aufgaben.length > 0 && (
                    <section>
                      <h3>Aufgaben</h3>
                      <ul>
                        {jobDetails.aufgaben.map((aufgabe, index) => (
                          <li key={index}>{aufgabe}</li>
                        ))}
                      </ul>
                    </section>
                  )}

                  {jobDetails.qualifikationen && jobDetails.qualifikationen.length > 0 && (
                    <section>
                      <h3>Qualifikationen</h3>
                      <ul>
                        {jobDetails.qualifikationen.map((qual, index) => (
                          <li key={index}>{qual}</li>
                        ))}
                      </ul>
                    </section>
                  )}

                  {jobDetails.arbeitgeberInfo && (
                    <section>
                      <h3>Über uns</h3>
                      <p>{jobDetails.arbeitgeberInfo}</p>
                    </section>
                  )}

                  {selectedJob.branche && (
                    <section>
                      <h3>Branche</h3>
                      <p>{selectedJob.branche.bezeichnung}</p>
                    </section>
                  )}

                  {jobDetails.arbeitgeberAdresse && (
                    <section>
                      <h3>Standort</h3>
                      <p>
                        {jobDetails.arbeitgeberAdresse.strasse} {jobDetails.arbeitgeberAdresse.hausnummer}<br />
                        {jobDetails.arbeitgeberAdresse.plz} {jobDetails.arbeitgeberAdresse.ort}
                      </p>
                    </section>
                  )}

                  {jobDetails.befristung && (
                    <section>
                      <h3>Befristung</h3>
                      <p>{jobDetails.befristung}</p>
                    </section>
                  )}

                  {jobDetails.eintrittsdatum && (
                    <section>
                      <h3>Eintrittsdatum</h3>
                      <p>{new Date(jobDetails.eintrittsdatum).toLocaleDateString('de-DE')}</p>
                    </section>
                  )}
                </>
              ) : (
                <div className="error-message">
                  <p>Details konnten nicht geladen werden.</p>
                </div>
              )}

              <div className="apply-section">
                <a 
                  href={selectedJob.externeUrl} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="apply-button"
                >
                  Jetzt bewerben
                </a>
                <button 
                  className={`save-button ${savedJobs.includes(selectedJob.hashId) ? 'active' : ''}`}
                  onClick={() => toggleSaveJob(selectedJob.hashId)}
                >
                  {savedJobs.includes(selectedJob.hashId) ? 'Gespeichert' : 'Speichern'}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobSearch;
