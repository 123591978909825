import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Quellen.scss';

const Quellen = () => {
  const navigate = useNavigate();

  return (
    <div className="quellen-container">
      <button className="close-button" onClick={() => navigate('/')}>
        X
      </button>
      <h2>Quellen</h2>
      <a className='quellen-nummerierung'>1. Letzter Zugriff am 17.01.2025 – </a> <a href="https://www.ausbildung.de/berufe/mtla/karriere/" className='quellen-text'>
      https://www.ausbildung.de/berufe/mtla/karriere/<br />
      </a>
      <a className='quellen-nummerierung'>2. Letzter Zugriff am 17.01.2025 – </a> <a href="https://dvta.de/fortbildung/fortbildungen" className='quellen-text'>
      https://dvta.de/fortbildung/fortbildungen<br />
      </a>
      <a className='quellen-nummerierung'>3. Letzter Zugriff am 17.01.2025 – </a> <a href="https://www.ludwig-fresenius.de/fort-und-weiterbildung/gesundheit-und-soziales/mtl/" className='quellen-text'>
        https://www.ludwig-fresenius.de/fort-und-weiterbildung/gesundheit-und-soziales/mtl/<br />
      </a>
      <a className='quellen-nummerierung'>4. Letzter Zugriff am 17.01.2025 – </a> <a href="https://www.hochschulkompass.de/home.html" className='quellen-text'>
      https://www.hochschulkompass.de/home.html<br />
      </a>
    </div>
  );
};

export default Quellen;
